.break {
  flex-basis: 100%;
  height: 0;
}

.roulette__table {
  display: flex;
  flex-wrap: wrap;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.rotating {
  -webkit-animation: rotating 5s linear infinite;
  -moz-animation: rotating 5s linear infinite;
  -ms-animation: rotating 5s linear infinite;
  -o-animation: rotating 5s linear infinite;
  animation: rotating 5s linear infinite;
}

.roulette__wheel__rotate {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  animation: rotating 9s linear infinite;
  -webkit-animation: rotating 9s linear infinite;
}

.roulette__number__red {
  position: relative;
  padding: 10px 10px 10px 10px;
  border: 1px solid red;
  border-radius: 5px;
  margin: 25px;
  width: 350px;
  height: 200px;
  text-align: center;
}

.roulette__number {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: red;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  /* Apply negative top and left margins to truly center the element */
}

.roulette__number__black {
  padding: 10px;
}
