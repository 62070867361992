@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-Thin.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-ThinItalic.woff2") format("woff2");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-LightItalic.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-RegularItalic.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-MediumItalic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-BoldItalic.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-Heavy.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Camphor";
    src: url("./font/camphor/CamphorPro-HeavyItalic.woff2") format("woff2");
    font-weight: 900;
    font-style: italic;
}

body {
    font-family: "Camphor", system-ui, -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}

.barCnt {
    position: relative;
    height: 10px;
    padding: 5px 0px;
}

.barCnt .preBar {
    position: absolute;
    background-color: blue;
    height: 5px;
    line-height: 5px;
    z-index: 200;
    border-radius: 3px;
    padding: 0px;
    margin: 0px;
    pointer-events: none;
}

input[type=range].colorized {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    position: absolute;
    padding: 0px;
    margin: 0px;
    cursor: ew-resize;
    z-index: 100;
    left: 0px
}

input[type=range].colorized::-webkit-slider-runnable-track {
    height: 5px;
    background: orange;
    border: none;
    border-radius: 3px;
}

input[type=range].colorized::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: blue;
    margin-top: -4px;
    z-index: 400;
}

input[type=range].colorized:focus {
    outline: none;
}

input[type=range].colorized:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

input[type=range].colorized::-moz-range-track {
    width: 100%;
    height: 5px;
    background: orange;
    border: none;
    border-radius: 3px;
}

input[type=range].colorized::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: blue;
    z-index: 400;
}

input[type=range].colorized::-moz-focus-outer {
    border: 0;
}


#keno-item-container {
    display: inline-block;
    position: relative;
    width: 50%;
}

#keno-item-dummy {
    margin-top: 75%;
    /* 4:3 aspect ratio */
}

#keno-item-element {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* show me! */
}

/** button animation */

.btn:active {
    animation: button-pop 0.25s ease-out;
}

.container-ez {
    animation: button-pop 0.25s ease-out;
}

@keyframes button-pop {
    0% {
        transform: scale(.70)
    }

    40% {
        transform: scale(1.04)
    }

    to {
        transform: scale(1)
    }
}

/* card flip animation */

.card:hover .card-content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.card-front,
.card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    line-height: 300px;
    color: #03446A;
    text-align: center;
    font-size: 60px;
    border-radius: 5px;
    backface-visibility: hidden;
}

.card-back {
    background: #03446A;
    color: white;
    transform: rotateY(180deg);
}


/* reflection */

.reflect-x {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}